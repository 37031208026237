<template>
  <div class="verify-corporate-username">
    <ValidationObserver
      v-slot="{handleSubmit}"
      slim>
      <form
        @submit.prevent="handleSubmit(submit)"
        :class="['d-flex', 'flex-column', 'justify-content-between', (submitting) ? 'disabled' : '']"
        method="post">
        <div>
          <div class="company-info">
            <div class="company-logo">
              <img
                v-if="company && company.logo"
                :src="`data:image/png;base64, ${company.logo}`"
                :alt="company.name">
            </div>
          </div>
          <Confirmation
            v-if="!success && showConfirmBox"
            :success="success"
            :message="confirmMsg.message"
            :title="confirmMsg.title"
            class="pb-2 mb-6"/>
          <EmailField
            :rules="loggedIn?
              {} : {email_required: true, email: true, email_available: true}"
            :field-name="$t('email.label')"
            :placeholder="$t('email.placeholder')"
            :disabled="submitting || loggedIn || disabled"
            @input="showConfirmBox = false"
            name="email"/>
          <TextField
            v-if="company.requires_registration_code"
            v-model="registration_code"
            :rules="{required: true}"
            :field-name="$t('company.reg.code')"
            :placeholder="$t('company.reg.placeholder')"
            @input="showConfirmBox = false"
            :disabled="submitting"
            name="registration_code"/>
          <ClientOnly>
            <div
              v-if="!loggedIn"
              class="note mb-4">
              {{ $t('register.page.note') }}
            </div>
          </ClientOnly>
        </div>
        <div>
          <TermsAndConditionsView
            :title="$t('terms.and.conditions.accept')"
            :body="$t('terms.and.conditions.body', { pub: publication.title })"
            :show-terms-and-conditions="!loggedIn"
            class="mt-0">
            <template #container>
              <LoadingButton
                :disabled="submitting || disabled"
                :loading="submitting"
                button-class="btn-standard mt-0">
                {{ loggedIn ? $t('register.button.text.logged.in') : $t('register.button.text') }}
              </LoadingButton>
              <button
                :disabled="disabled || submitting"
                @click="$emit('back')"
                type="button"
                class="btn-secondary mt-3">
                {{ $t('go.back') }}
              </button>
            </template>
          </TermsAndConditionsView>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import LoadingButton from '@components/elements/LoadingButton';
import EmailField from '@components/elements/EmailField';
import TextField from '@components/elements/TextField';
import Confirmation from '@components/elements/Confirmation.vue';
import TermsAndConditionsView from '@components/elements/TermsAndConditionsView';
import { ajaxForm, corporateIp, emailSync, formDescriptionHander, userExists } from '@mixins';
import ClientOnly from '@components/ClientOnly';

export default {
  name: 'verify-corporate-username',
  components: {
    ClientOnly,
    Confirmation,
    EmailField,
    TextField,
    LoadingButton,
    ValidationObserver,
    TermsAndConditionsView
  },
  mixins: [emailSync, ajaxForm, userExists, corporateIp, formDescriptionHander],
  data() {
    return {
      watchForFormHeadingSubHeadingChanges: true,
    };
  },
  computed: {
    companyName: function () {
      return this.capitalize(this.company.name || this.company.subscriber_name || '');
    },
    company: function () {
      return this.$store.state.corporate.company;
    },
  },
  watch: {
    company: {
      immediate: true,
      handler() {
        if (this.company) {
          this.formHeading = this.company.registration_screen_title || '';
          let subHeading = '';
          if (this.company.registration_screen_body) {
            subHeading += `<p>${this.company.registration_screen_body || ''}</p>`;
          }
          if (this.loggedIn && this.company.greeting_logged_in) {
            subHeading += `<p>${this.company.greeting_logged_in || ''}</p>`;
          }
          if (!this.loggedIn && this.company.greeting_not_logged_in) {
            subHeading += `<p>${this.company.greeting_not_logged_in || ''}</p>`;
          }
          this.formSubHeading = subHeading;
        }
      }
    }
  },
  methods: {
    successWorkflow() {
      this.$emit('success');
    },
    errorWorkflow() {
      this.disabled = false;
    },
    submit() {
      const app = this;
      const slug = this.$route.params.slug || '';
      app.submitForm(
        () => app.subscriptionClient.getIpCompany(slug, app.email),
        false
      );
    },
  },
};
</script>

<style lang="scss" scoped>

.company-info {
  .company-desc {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    line-height: 14.4px;
    text-align: left;
  }

  .company-logo {
    margin-bottom: 40px;
  }

  img {
    margin: auto;
  }
}
</style>
