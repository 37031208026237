<template>
  <AuthContainer>
    <template #form>
      <NhstFreeUserRegistration v-if="showFreeRegForm" />
      <NhstRegistration v-else />
    </template>
  </AuthContainer>
</template>

<script>
import {
  NhstRegistration,
  AuthContainer,
  NhstFreeUserRegistration
} from '../components';
import Page from '@pages/Page.js';

export default {
  name: 'registration-page',
  components: {
    AuthContainer,
    NhstRegistration,
    NhstFreeUserRegistration
  },
  extends: Page,
  computed: {
    showFreeRegForm() {
      return this.isDN || this.isHydrogen;
    }
  },

  methods: {
    getTitle() {
      return `${this.$t('registration.page.title')} | ${this.publication.title}`;
    },

  }
};

</script>
