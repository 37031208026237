const corporateIp = {
  data() {
    return {
      errorMsgs: {
        '400.ip_login_not_found': {
          message: this.$t('ip.registration.400.not.found.description'),
          title: this.$t('ip.registration.400.not.found.title'),
        },
        '400.registration_not_allowed': {
          message: this.$t('ip.registration.400.registration.not.allowed.description'),
          title: this.$t('ip.registration.400.registration.not.allowed.title'),
        },
        '400.user_has_subscription': {
          message: this.$t('ip.registration.400.user.has.subscription.description'),
          title: this.$t('ip.registration.400.user.has.subscription.title'),
        },
        '400.invalid_domain': {
          message: this.$t('ip.registration.400.invalid.domain.description'),
          title: this.$t('ip.registration.400.invalid.domain.title'),
        },
        '400.invalid_registration_code': {
          message: this.$t('ip.registration.400.invalid.registration.code.description'),
          title: this.$t('ip.registration.400.invalid.registration.code.title'),
        },
      }
    };
  },
  computed: {
    confirmMsgKey: function () {
      let err = '';
      if (typeof this.serverResponseMessage === 'object') {
        err = this.serverResponseMessage.error_codes;
        if (Array.isArray(err)) {
          err = err[0];
        }
      }
      return `${this.serverResponseCode || ''}.${err}`;
    },
    confirmMsg: function () {
      return this.errorMsgs[this.confirmMsgKey] || { title: '', message: this.$t('auth.error.unexpected') };
    }
  }
};

export { corporateIp };
