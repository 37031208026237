var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.fieldName,"rules":_vm.rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('div',_vm._b({staticClass:"d-flex justify-content-start checkbox-wrapper"},'div',_vm.$attrs,false),[_c('div',[_c('InlineSvg',{class:[
            'checkbox',
            _vm.inputVal ? 'active' : 'inactive',
            _vm.$attrs.disabled ? 'disabled': '',
            errors.length > 0 ? 'error-border': ''
          ],attrs:{"src":_vm.$cdn.resource('checkbox.svg'),"alt":"checkbox"},on:{"click":_vm.handleClick}}),_vm._v(" "),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputVal)?_vm._i(_vm.inputVal,null)>-1:(_vm.inputVal)},on:{"change":function($event){var $$a=_vm.inputVal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputVal=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputVal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputVal=$$c}}}})],1),_vm._v(" "),_c('div',[_c('div',{staticClass:"ps-3",domProps:{"innerHTML":_vm._s(_vm.text)}})])]),_vm._v(" "),_c('transition',{attrs:{"name":"expand","mode":"out-in"}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"error-msg ps-6",domProps:{"innerHTML":_vm._s(errors[0])}})])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }